import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import { VueCookieNext } from 'vue-cookie-next'


// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }
});

createApp(App)
  .use(store)
  .use(router)
  .use(VueCookieNext)
  .mount("#app");

VueCookieNext.config({ expire: '730d' })