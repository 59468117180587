import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import NotFound from "@/views/NotFound";
import PrivacyPolicy from "@/views/PrivacyPolicy";

const routes = [
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: NotFound,
    meta: {
      title: "No page here..."
    }
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Florian Wieland - wieli.at"
    }
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: PrivacyPolicy,
    meta: {
      title: "Privacy Policy for wieli.at"
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
