<template>
  <div class="home">
    <BaseTitle title="Welcome"></BaseTitle>

    <div id="intro-text">
      This is a page owned by Florian Fritz. Its purpose is to provide general information about Florian Fritz and contact details.
    </div>

    <h2>Florian Fritz</h2>

    <img src="../assets/img/profile.webp" alt="a picture of Florian Fritz" />

    <div class="card">
      I'm currently employed at akaryon, working as a web app and WordPress
      developer. You can contact me at
      <a target="_blank" href="mailto:florian@wieli.at">florian@wieli.at</a>
    </div>
  </div>
</template>

<script>
import BaseTitle from "@/components/BaseTitle";

export default {
  name: "Home",
  components: { BaseTitle }
};
</script>

<style scoped>
* {
  text-align: center;
}
img {
  width: 12rem;
  height: 12rem;
  margin-left: auto;
  margin-right: auto;
  display: block;
  border-radius: 50%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 2px 3px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  margin-bottom: 50px;
}

img:hover {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25), 0 3px 8px rgba(0, 0, 0, 0.22);
}

#intro-text {
  margin: 5% 0;
}

.card {
  width: 20rem;
}

@media only screen and (max-width: 700px) {
  .home {
    margin-bottom: 5rem;
  }
}

@media only screen and (max-width: 350px) {
  .card {
    width: 18rem;
  }
}
</style>
