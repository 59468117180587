<template>
  <router-view />
  <transition name="slide">
    <footer v-show="!cookieConsentGiven">
      <div id="cookie-consent">
        <div id="cookie-message">
          This site uses cookies to deliver third-party data.
          <br />
          <router-link :to="{ name: 'Privacy' }"
            >Read more about your Privacy here</router-link
          >
        </div>
        <button id="cookie-consent-button" @click="setCookieConsent">
          Got it!
        </button>
      </div>
    </footer>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      cookieConsentGiven: this.$cookie.isCookieAvailable("cookieConsentCookie"),
      displayHamburgerMenu: false,
      windowWidth: window.innerWidth
    };
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  methods: {
    setCookieConsent() {
      this.$cookie.setCookie("cookieConsentCookie");
      this.cookieConsentGiven = true;
    }
  }
};
</script>

<style>
@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins-Regular.ttf);
}

* {
  font-family: Poppins, sans-serif;
  color: #888888;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  padding: calc(1.5% + 1.5rem) 5% 5% 5%;
}

h1 {
  font-size: 3rem !important;
}

h2 {
  font-size: 2rem;
}

nav {
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  background-color: rgba(216, 222, 233, 0.8);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  border-bottom: 1px solid #4C566A;
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem 0;
}

nav:hover {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25), 0 3px 8px rgba(0, 0, 0, 0.22);
}

nav a {
  font-size: 1.5rem;
  text-decoration: none;
  margin-right: 1%;
  margin-left: 3%;
}

#hamburger {
  display: none;
}

.router-link-active {
  color: #2E3440;
}

footer {
  background-color: rgba(59, 66, 82, 0.8) !important;
  z-index: 10000;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}

#cookie-consent {
  display: flex;
  justify-content: center;
  padding: 1.5rem;
}

#cookie-consent-button {
  width: 10rem;
  margin-left: 5%;
}

#cookie-consent-button:hover {
  background-color: rgb(66, 66, 66) !important;
}

#cookie-consent-button:active {
  background-color: rgba(66, 66, 66, 0.8) !important;
}

.slide-leave-active {
  bottom: -100%;
  transition: 1s;
}

footer * {
  color: #dddddd;
}

.card {
  display: block;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 2vh;
  border-radius: 8px;
  width: 28rem;
  height: fit-content;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 2px 3px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  margin-bottom: 50px;
}

.card:hover {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25), 0 3px 8px rgba(0, 0, 0, 0.22);
}

input {
  padding: 0.7rem 0 0.7rem 0.7rem;
  border-radius: 25px;
  border: 2px solid #D8DEE9;
}

button {
  border: 2px solid #D8DEE9;
  outline: none;
  border-radius: 8px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  background-color: transparent;
  padding-bottom: 0;
}

button:hover {
  background-color: #E5E9F0;
}

button:active {
  background-color: #ECEFF4;
}

.leaflet-control-zoom,
.leaflet-control-attribution {
  display: none !important;
}

/* Scrollbar */
html {
  scrollbar-face-color: #D8DEE9;
  scrollbar-base-color: #D8DEE9;
  scrollbar-3dlight-color: #D8DEE9;
  scrollbar-highlight-color: #D8DEE9;
  scrollbar-track-color: #ECEFF4;
  scrollbar-arrow-color: #ECEFF4;
  scrollbar-shadow-color: #D8DEE9;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-button {
  display: none;
}
::-webkit-scrollbar-track {
  background-color: #D8DEE9;
}
::-webkit-scrollbar-track-piece {
  background-color: #E5E9F0;
}
::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: #D8DEE9;
  border-radius: 0.5rem;
}
::-webkit-scrollbar-corner {
  background-color: #D8DEE9;
}
::-webkit-resizer {
  background-color: #D8DEE9;
}

@media (prefers-color-scheme: dark) {
  * {
    color: #D8DEE9;
  }
  body,
  input {
    background-color: #2e3440;
  }
  .card {
    background-color: #3b4252;
  }

  nav {
    background-color: rgba(59, 66, 82, 0.8) !important;
    border: none;
  }

  @media only screen and (max-width: 700px) {
    nav {
      background-color: rgba(59, 66, 82, 1) !important;
    }
  }

  .router-link-active {
    color: white;
  }

  button:hover {
    background-color: #434c5e;
  }

  button:active {
    background-color: #4c566a;
  }

  html {
    scrollbar-face-color: #4c566a !important;
    scrollbar-base-color: #4c566a !important;
    scrollbar-3dlight-color: #4c566a !important;
    scrollbar-highlight-color: #4c566a !important;
    scrollbar-track-color: #2e3440 !important;
    scrollbar-arrow-color: #2e3440 !important;
    scrollbar-shadow-color: #4c566a !important;
  }

  ::-webkit-scrollbar {
    width: 8px !important;
    height: 8px !important;
  }
  ::-webkit-scrollbar-button {
    display: none !important;
  }
  ::-webkit-scrollbar-track {
    background-color: #4c566a !important;
  }
  ::-webkit-scrollbar-track-piece {
    background-color: #2e3440 !important;
  }
  ::-webkit-scrollbar-thumb {
    height: 50px !important;
    background-color: #4c566a !important;
    border-radius: 3px !important;
  }
  ::-webkit-scrollbar-corner {
    background-color: #4c566a !important;
  }
  ::-webkit-resizer {
    background-color: #4c566a !important;
  }

  .line {
    background: #d8dee9 !important;
  }
}

@media only screen and (max-width: 500px) {
  #app {
    padding: calc(1.5% + 1.5rem) 1% 5% 1%;
  }
}

@media only screen and (max-width: 700px) {
  #hamburger {
    display: initial;
  }

  nav {
    flex-direction: column;
    justify-content: flex-start;
    background-color: #E5E9F0;
    top: initial;
    bottom: 0;
  }

  nav.expanded {
    height: 100vh;
    flex-direction: column-reverse;
    text-align: end;
  }

  label.hamburg {
    display: block;
    width: 75px;
    height: 50px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    border-radius: 4px;
  }

  input#hamburg {
    display: none;
  }
  .line {
    position: absolute;
    left: 10px;
    height: 4px;
    width: 55px;
    background: #4C566A;
    border-radius: 2px;
    display: block;
    transition: 0.5s;
    transform-origin: center;
  }

  .line:nth-child(1) {
    top: 12px;
  }
  .line:nth-child(2) {
    top: 24px;
  }
  .line:nth-child(3) {
    top: 36px;
  }

  #hamburg:checked + .hamburg .line:nth-child(1) {
    transform: translateY(12px) rotate(-45deg);
  }

  #hamburg:checked + .hamburg .line:nth-child(2) {
    opacity: 0;
  }

  #hamburg:checked + .hamburg .line:nth-child(3) {
    transform: translateY(-12px) rotate(45deg);
  }
}
</style>
