import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    weatherData: JSON,
    news: JSON,
    position: {
      coords: {
        latitude: "47.443562",
        longitude: "15.290100"
      }
    },
    cityName: "Kapfenberg"
  },

  mutations: {
    SET_WEATHER_DATA(state, weatherData) {
      state.weatherData = weatherData;
    },
    SET_NEWS_DATA(state, newsData) {
      state.news = newsData;
    },
    SET_POSITION(state, position) {
      state.position = position;
    },
    SET_CITY_NAME(state, cityName) {
      state.cityName = cityName;
    }
  },

  actions: {
    async refreshWeatherData({ commit }, position) {
      const response = await axios.request({
        url:
          "https://api.openweathermap.org/data/2.5/onecall?lat=" +
          position.coords.latitude + "&lon=" + position.coords.longitude +
          "&units=metric&appid=3ce1c6c51b44455956a09fa606e3f23b",
        method: "get"
      });
      commit('SET_WEATHER_DATA', response.data);

      return Promise.resolve();
    },
    async refreshNewsData({ commit }) {
      const response = await axios.request({url:'https://gnews.io/api/v4/top-headlines?lang=en&topic=technology&token=9a9187d0494ec0a247980a708793189d',method:'get'});
      commit('SET_NEWS_DATA', response.data.articles[Math.floor(Math.random() * 9)]);
    },
    async searchByCity({ commit }, cityName) {
      const response = await axios.request({url:'https://eu1.locationiq.com/v1/search.php?key=d2c1d2b99010ab&q=city,' + cityName + "&format=json",method:'get'});
      let position = {
        coords: {
          latitude: response.data[0].lat,
          longitude: response.data[0].lon
        }
      };
      commit('SET_POSITION', position)
      commit('SET_CITY_NAME', cityName)
    },
    async setCityNameFromLocation({ commit }, position) {
      const response = await axios.request({url:'https://eu1.locationiq.com/v1/reverse.php?key=d2c1d2b99010ab&normalizecity=1&lat=' +
          position.coords.latitude + "&lon=" + position.coords.longitude + "&format=json",method:'get'});
      let cityName = response.data.address.city;
      commit('SET_CITY_NAME', cityName)
    },
    setCityName({ commit }, cityName) {
      commit('SET_CITY_NAME', cityName)
    },
    setPosition({ commit }, position) {
      commit('SET_POSITION', position)
    }
  },

  getters: {
    current: state => {
      return state.weatherData.current;
    },
    hourly: state => {
      return state.weatherData.hourly;
    },
    daily: state => {
      return state.weatherData.daily;
    },
    position: state => {
      return state.position;
    },
    cityName: state => {
      return state.cityName;
    },
    news: state => {
      return state.news;
    }
  }
});
