<template>
  <h1>This site was not found</h1>
</template>

<script>
export default {
  name: "NotFoundComponent"
};
</script>

<style scoped>

</style>