<template>
  <BaseTitle title="Privacy"></BaseTitle>

  <div class="card">
    This website uses cookies for user experience improvements. No personal data
    is collected or processed. Cookies are being stored for up to 730 days upon
    creation.
  </div>

  <div class="card">
    Please note, that in order to deliver weather, news and other data, your
    Browser wil perform a request and receive data from to the following third
    party providers:
    <ul>
      <li>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://openweathermap.org/"
        >
          OpenWeather</a
        >
        - for weather and location data
      </li>
      <li>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.mapbox.com/"
        >
          mapbox</a
        >
        - for map card
      </li>
    </ul>
  </div>
</template>

<script>
import BaseTitle from "@/components/BaseTitle";

export default {
  name: "PrivacyPolicy",
  components: { BaseTitle }
};
</script>

<style scoped>
.card {
  text-align: center;
}

ul {
  text-align: start;
  margin-top: 8%;
}

@media only screen and (max-width: 540px) {
  .card {
    width: 20rem;
  }
}
</style>
