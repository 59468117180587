<template>
  <header>
    <h1>{{ title }}</h1>
    <hr />
  </header>
</template>

<script>
export default {
  name: "BaseTitle",
  props: {
    title: String
  }
};
</script>

<style scoped>
header {
  margin: 5rem 0;
}
h1 {
  text-align: center;
}
hr {
  width: 50%;
}


@media only screen and (max-width: 700px){
  header {
    margin-top: 0;
  }
}
</style>
